class SvelteComponentsLoader {
  constructor() {
    this.components = {};
  }

  setup(components) {
    this.components = { ...this.components, ...components };
    this.loadComponentsOnDOMReady();
  }

  loadComponentsOnDOMReady() {
    document.addEventListener("DOMContentLoaded", () => {
      document.querySelectorAll("[data-svelte-component]").forEach((element) => {
        const name = element.dataset.svelteComponent;
        const props = JSON.parse(element.dataset.svelteProps || "{}");
        const Component = this.components[name];
        if (Component) {
          new Component({ target: element, props });
        } else {
          console.error(`No registered component found for ${name}`);
        }
      });
    });
  }
}

const svelteLoader = new SvelteComponentsLoader();
export default svelteLoader;
